import { SavedDepositState } from './../../profile/edit-profile.models';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { switchMap, take, tap } from 'rxjs';
import { HOST_NAME } from 'src/app/app-routing.module';
import { environment } from 'src/environments/environment';
import { UserFullInfoService } from '../../auth/check-session/user-full-info.service';
import {
    ExternalPaymentResponse,
    PAYMENT_IQ_STATUS_CODES,
    PaymentIqResponse,
    PaymentIqWIthdrawalResponse,
    PaymentMethod,
    WithdrawalMethod
} from './methods.models';
import { WINDOW } from '@ng-web-apis/common';
import { DepositStateService } from '../../profile/deposit-state.service';

const METHOD_DEPOSIT = "deposit";
const METHOD_WITHDRAWAL = "withdrawal";

@Injectable({
    providedIn: 'root'
})
export class PaymentIqService {

    constructor(
        private http: HttpClient,
        private userInfoService: UserFullInfoService,
        protected cookie: CookieService,
        private depositStateService: DepositStateService,
        @Inject(LOCALE_ID) private locale,
        @Inject(HOST_NAME) private hostname,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Document,

    ) {

    }
    createHeaders(): HttpHeaders {
        const newHeaders = {};
        newHeaders['Content-Type'] = 'application/json';
        return new HttpHeaders(newHeaders);
    }


    getPaymentMethods() {
        return this.userInfoService.requestFullInfo().pipe(
            take(1),
            switchMap(user => {
                const params = new HttpParams()
                    .set('sessionId', user.session)
                    .set('method', METHOD_DEPOSIT)
                    .set('locale', this.locale);
                const options = {
                    params: params,
                    headers: this.createHeaders(),
                };
                return this.http.get<any>(`${environment.paymentIqConfig.url}/user/payment/method/${environment.paymentIqConfig.mid}/${user.id}?`, options).pipe(
                    tap(x => console.log(x))
                )
            })
        )
    }

    submitTransaction(depositForm: UntypedFormGroup, method: PaymentMethod) {

        return this.userInfoService.requestFullInfo().pipe(
            switchMap(user => {
                let payload = {
                    sessionId: user.session,
                    userId: (environment.paymentIqConfig.userPrefix || '') + user.id,
                    merchantId: environment.paymentIqConfig.mid,
                    amount: depositForm.get('amount').value,
                    ...(!!method.service && { service: method.service }),
                    attributes: {
                        successUrl: `https://${this.hostname}/${this.locale}?action=payment&value=ok`,
                        failureUrl: `https://${this.hostname}/${this.locale}?action=payment&value=fail`,
                        pendingUrl: `https://${this.hostname}/${this.locale}?action=payment&value=pending`,
                        cancelUrl: `https://${this.hostname}/${this.locale}?action=payment&value=abort`,
                        ...(depositForm.get('bonus_id')?.value !== 0 && { bonus_id: depositForm.get('bonus_id')?.value }),
                        ...(depositForm.get('freespin_code')?.value !== '' && { freespins_id: depositForm.get('freespin_code')?.value }),
                    },
                    ...(!!depositForm.get('browserInformation')?.value && {
                        threeDS2: {
                            browserInformation: depositForm.get('browserInformation')?.value
                        }
                    })
                };
                for (const [key, value] of Object.entries(depositForm.value)) {
                    if (!['amount', 'bonus_id', 'freespin_code', 'browserInformation'].includes(key)) {
                        payload.attributes[key] = value;
                        payload[key] = value;
                    }
                }

                const state: SavedDepositState = {
                  amount: depositForm.get('amount').value,
                  payment_id: depositForm.get('payment_id').value,
                  bonus_id: depositForm.get('bonus_id').value,
                  code_id: depositForm.get('code_id').value,
                  freespin_code: depositForm.get('freespin_code').value,
                  isFreeSpin: false
                };

                this.depositStateService.addDepositState(state);

                const params = new HttpParams()
                    .set('locale', this.locale)
                const options = {
                    params: params,
                    headers: this.createHeaders(),
                };
                return this.http.post<PaymentIqResponse>(`${environment.paymentIqConfig.url}/${depositForm.get('payment_id').value.toLowerCase()}/${METHOD_DEPOSIT}/process?`, payload, options)
            })
        )
    }

    processResponse(response: PaymentIqResponse): ExternalPaymentResponse {
        if (!!response.redirectOutput?.url) {
            if (response.redirectOutput.container !== 'iframe') {
                window.location.href = response.redirectOutput.url;
            }
            else {
                let fields = ''
                if (response.redirectOutput.method == 'GET') {
                    const html = `<html><head><title>PaymentIQ Redirect</title></head>
                            <body style="margin: 0;"><div style="width:100%;height:100%">
                            <iframe  style="width:100%;height:100%;border: none;" src="${response.redirectOutput.url}">
                            </form>
                            </div></body>
                        </html>`

                    this.document.write(html);
                } else {
                    Object.keys(response.redirectOutput.parameters).forEach(param => {
                        fields += `<input type='hidden' name='${param}' value='${response.redirectOutput.parameters[param]}' />`
                    })

                    const html = `<html><head><title>PaymentIQ Redirect</title></head>
                            <body  style="margin: 0;"><div>
                            <form id='providerRedirectForm' action=${response.redirectOutput.url} method='post' name='provider-redirect-form-window'>
                                ${fields}
                            </form>
                            </div></body>
                        </html>`

                    this.document.write(html);
                    (this.document.getElementsByName('provider-redirect-form-window')[0] as any).submit()
                }
            }
            return { isError: false, response: response };
        }
        else if (response.txState === PAYMENT_IQ_STATUS_CODES.FAILED) {
            return {
                isError: true,
                errorMsg: response.errors[0]?.msg,
                response: response
            };
        }
    }

    getWithdrawalMethods() {
        return this.userInfoService.requestFullInfo().pipe(
            switchMap(user => {
                const params = new HttpParams()
                    .set('sessionId', user.session)
                    .set('method', METHOD_WITHDRAWAL)
                    .set('locale', this.locale);
                const options = {
                    params: params,
                    headers: this.createHeaders(),
                };
                return this.http.get<any>(`${environment.paymentIqConfig.url}/user/payment/method/${environment.paymentIqConfig.mid}/${user.id}?`, options).pipe(
                    tap(x => console.log(x))
                )
            })
        )
    }

    submitWithdrawal(withdrawalForm: UntypedFormGroup, method: WithdrawalMethod) {

        return this.userInfoService.requestFullInfo().pipe(
            switchMap(user => {
                let payload = {
                    sessionId: user.session,
                    userId: (environment.paymentIqConfig.userPrefix || '') + user.id,
                    merchantId: environment.paymentIqConfig.mid,
                    amount: withdrawalForm.get('amount').value,
                    ...(!!method.service && { service: method.service }),
                    attributes: {
                        successUrl: `https://${this.hostname}/${this.locale}?action=payment.message&value=ok`,
                        failureUrl: `https://${this.hostname}/${this.locale}?action=payment.message&value=fail`,
                        pendingUrl: `https://${this.hostname}/${this.locale}?action=payment.message&value=pending`,
                        cancelUrl: `https://${this.hostname}/${this.locale}?action=payment.message&value=abort`,
                    }
                };
                for (const [key, value] of Object.entries(withdrawalForm.value)) {
                    if (!['amount', 'bonus_id', 'freespin_code'].includes(key)) {
                        payload.attributes[key] = value;
                        payload[key] = value;
                    }
                }
                const params = new HttpParams()
                    .set('locale', this.locale)
                const options = {
                    params: params,
                    headers: this.createHeaders(),
                };
                return this.http.post<PaymentIqWIthdrawalResponse>(`${environment.paymentIqConfig.url}/${withdrawalForm.get('payment_id').value.toLowerCase()}/${METHOD_WITHDRAWAL}/process?`, payload, options)
            })
        )
    }

    processWithdrawalResponse(response: PaymentIqWIthdrawalResponse): ExternalPaymentResponse {
        if (response.success) {
            return {
                isError: false,
            };
        }
        else if (!response.success) {
            return {
                isError: true,
                errorMsg: response.errors[0]?.msg.replace('.', ' ')
            };
        }
    }

    deleteAccount(accountId: string) {
        return this.userInfoService.requestFullInfo().pipe(
            switchMap(user => {
                const params = new HttpParams()
                    .set('sessionId', user.session);
                const options = {
                    params: params,
                    headers: this.createHeaders(),
                };
                return this.http.delete<any>(`${environment.paymentIqConfig.url}/user/account/${environment.paymentIqConfig.mid}/${(environment.paymentIqConfig.userPrefix || '') + user.id}/${accountId}`, options);
                // return of(null)
            })
        );
    }
}




