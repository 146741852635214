<!-- <ng-container *ngIf="!toggle"> -->
<div class="sidnav-wrapper">
  <div class="top-container menu-item" fxLayout="row" fxLayoutAlign="space-between center">
    <!-- <button mat-icon-button (click)="callLinkClickedParent()"><img src="assets/desktop/ham-close-btn.svg"></button> -->
    <app-close-dialog-button (click)="callLinkClickedParent()" [position]="true"></app-close-dialog-button>
    <img class="logo-top" src="{{licenceService.getKey('logo')}}" alt="logo-green">
  </div>
  <div class="list-menu-1 menu-item">
    <ng-container *ngIf="!isLogin else toploggeduser">
      <div class="open-account-button">
        <button round-button color="accent" size="large" (click)="signup()" filled="filled" i18n>Open Account</button>
      </div>
    </ng-container>
    <ng-template #toploggeduser>
      <div class="logged-top-info" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <app-profile-info (closeEvent)="callLinkClickedParent()" [visible]="isVisible" size="small"></app-profile-info>
        <app-top-menu-balance class="balance-info" (closeEventDeposit)="callLinkClickedParent()"></app-top-menu-balance>
      </div>
    </ng-template>
    <mat-selection-list [multiple]="false" class="nav-bar-first-three">
      <a routerLink="/">
        <mat-list-option [value]="" (click)="callLinkClickedParent()">
          <img mat-list-icon src="{{licenceService.getKey('icon-home')}}"> <span i18n>Home</span>
        </mat-list-option>
      </a>
      <a [routerLink]="slot">
        <mat-list-option [value]="" (click)="callLinkClickedParent()">
          <img mat-list-icon src="{{licenceService.getKey('icon-slots')}}"> <span i18n>Slots</span>
        </mat-list-option>
      </a>
      <a [routerLink]="liveCasinoRoute">
        <mat-list-option [value]="" (click)="callLinkClickedParent()">
          <img mat-list-icon src="{{licenceService.getKey('icon-live-casino')}}"> <span i18n>live casino</span>
        </mat-list-option>
      </a>
    </mat-selection-list>
  </div>
  <mat-selection-list [multiple]="false" class="list-menu-2 menu-item">
    <ng-container *ngFor="let menu of childData.topMenuItems">
      <ng-container *ngIf="!menu.requireLogin then menuitem">
      </ng-container>
      <ng-container *ngIf="menu.requireLogin && isLogin then menuitem">
      </ng-container>
      <ng-template #menuitem>
        <div *ngIf="menu.page" [class]="menu.colorName !== null?menu.colorName:'regular'" #firstNavBar>
          <a routerLink="/{{ menu.page?.category }}/{{ menu.page?.name }}">
            <mat-list-option (click)="callLinkClickedParent()" [value]="">
              <img mat-list-icon src="{{menu.icon?.url}}"> <span>{{menu.name}}</span>
            </mat-list-option>
          </a>
        </div>
        <div *ngIf="menu.inAppUrl" [class]="menu.colorName !== null?menu.colorName:'regular'">
          <a routerLink="/{{ menu.inAppUrl }}">
            <mat-list-option (click)="callLinkClickedParent()" [value]="">
              <img mat-list-icon src="{{menu.icon?.url}}"> <span>{{menu.name}}</span>
              <img class="docs-needed-icon" *ngIf="menu.inAppUrl === 'upload-documents' && hasDocumentRequest" mat-list-icon src="{{licenceService.getKey('icon-exclamation')}}">
              <div class="pending-withdrawals-icon" *ngIf="menu.inAppUrl === 'pending-withdrawals' && !!pendingList?.count" fxLayout="row" fxLayoutAlign="center center" mat-list-icon>
                <div class="count">{{pendingList?.count}}</div>
              </div>
            </mat-list-option>
          </a>
        </div>
      </ng-template>
      <!-- <ng-template #menuitem>
        <a routerLink="/{{ menu.page?.category }}/{{ menu.page?.name }}">
          <mat-list-option [class]="menu.colorName !== null?menu.colorName:'regular'" (click)="callLinkClickedParent()" [value]="" *ngIf="menu.page">
            <img mat-list-icon src="{{menu.icon?.url}}"> <span>{{menu.name}}</span>
          </mat-list-option>
        </a>
        <a routerLink="/{{ menu.inAppUrl }}">
          <mat-list-option [class]="menu.colorName !== null?menu.colorName:'regular'" (click)="callLinkClickedParent()" routerLink="/{{ menu.inAppUrl }}" [value]="" *ngIf="menu.inAppUrl">
            <img mat-list-icon src="{{menu.icon?.url}}">
            <div style="position: relative" fxLayoutAlign="start center" fxLayout="row">
              <div>{{menu.name}}</div>
              <ng-container *ngIf="menu.inAppUrl === 'inbox' && unreadMessages > 0">
                <div class="notification-circle">
                  <span class="notification-number">{{unreadMessages}}</span>
                </div>
              </ng-container>
            </div>

          </mat-list-option>
        </a>
      </ng-template> -->
      <!-- <mat-divider *ngIf="menu.page?.name==='treasure-boxes'"></mat-divider> -->
    </ng-container>
    <!-- <mat-list-option [value]="">
            <img mat-list-icon src="assets/desktop/promotions.svg"> <span i18n>Promotion</span>
        </mat-list-option>
        <mat-list-option [value]="">
            <img mat-list-icon src="assets/desktop/treasure.svg"> <span i18n>Bison Scratch Cards</span>
        </mat-list-option>
        <mat-divider></mat-divider> -->
    <mat-form-field class="translate select-language" appearance="outline">
      <mat-label></mat-label>
      <mat-select panelClass="language-overlay d-language-overlay" [value]="selectedLanguage?.name" (selectionChange)="setCookie($event)">
        <mat-select-trigger class="selected-container">
          <img [src]="selectedLanguage?.flag?.url">
          <span>{{selectedLanguage?.fullName}}</span>
          <mat-icon matSuffix>arrow_forward_ios</mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let item of childData?.languages" [value]="item?.name">
          <a href="/{{item?.name}}/">
            <img class="select-img" [src]="item?.flag.url">
            <span>{{item?.fullName}}</span>
          </a>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-selection-list>
  <ng-container *loggedRender>
    <div class="menu-divider">&nbsp;</div>
    <div class="logout menu-item">
      <app-logout (click)="callLinkClickedParent()"></app-logout>
    </div>
  </ng-container>

  <!-- <mat-selection-list [multiple]="false" class="list-menu-3">
        <mat-list-option [value]="">
            <img mat-list-icon src="assets/desktop/support.svg"> <span i18n>Support</span>
        </mat-list-option>
        <mat-list-option [value]="">
            <img mat-list-icon src="assets/desktop/faq.svg"> <span i18n>Faq</span>
        </mat-list-option>
        <mat-list-option [value]="">
            <img mat-list-icon src="assets/desktop/terms.svg"> <span i18n>Terms & Conditions</span>
        </mat-list-option>
        <mat-list-option [value]="">
            <img mat-list-icon src="assets/desktop/exclusion-icon.svg"> <span i18n>Self-Exclusion</span>
        </mat-list-option>
      </mat-selection-list> -->


</div>
<!-- </ng-container> -->