const default_values = {
    "name": "Bison Casino",
    "logo-xmas": "assets/logo/lemon-logo-xmas-mobile.svg",
    "logo-desktop-xmas": "assets/logo/logo-xmas-desktop.svg",
    "logo": "assets/logo/2023/logo-xmas-mobile.svg",
    "logo-desktop": "assets/logo/2023/logo-xmas-desktop.svg",
    "full-logo": "assets/logo/logo-green.svg",
    "title-substitute": "",
    "search-icon": "assets/desktop/search-in.svg",
    "signup-step-one": "assets/desktop/signup/icon-signup-one.svg",
    "signup-step-two": "assets/desktop/signup/icon-signup-two.svg",
    "signup-step-three": "assets/desktop/signup/icon-signup-three.svg",
    "signup-banner": "assets/desktop/signup/signup-banner.png",
    "tournament-icon": "assets/playbison/icon-tournaments.png",
    "icon-add": "assets/icons/icon-add.svg",
    "sticky-profile": "assets/icons/icon-profile-sticky.svg",
    "sticky-category": "assets/icons/icon-categories-sticky.svg",
    "sticky-live": "assets/icons/icon-live-casino-sticky.svg",
    "sticky-wallet": "assets/icons/icon-wallet-sticky.svg",
    "sticky-search": "assets/icons/icon-search-sticky.svg",
    "btn-close": "assets/desktop/ham-close-btn.svg",
    "btn-cancel": "assets/icons/icon-cancel.svg",
    "btn-error": "assets/icons/icon-error.svg",
    "btn-edit": "assets/icons/icon-edit.svg",
    "btn-edit-full": "assets/icons/icon-edit-full.svg",
    "icon-notification": "assets/icons/icon-notification.svg",
    "icon-operator": "icon-operators.svg",
    "icon-pay-history": "assets/desktop/pay-history.svg",
    "icon-withdrawal": "assets/icons/icon-withdrawal.svg",
    "icon-edit-profile": "icon-edit-profile.svg",
    "upload-docs-icon": "assets/playbison/icons/upload-not-icon.svg",
    "icon-edit-gear": "assets/playbison/icons/edit-icon.svg",
    "icon-edit-pencil": "assets/playbison/icons/edit-pro-pencil.svg",
    "icon-edit-location": "assets/playbison/icons/edit-location.svg",
    "icon-edit-calender": "assets/playbison/icons/edit-calender.svg",
    "icon-deposit": "assets/icons/icon-deposit.svg",
    "icon-right": "assets/desktop/arrow-button.svg",
    "icon-info": "assets/client-area/icon-info.svg",
    "icon-winnings": "assets/icons/winnings.svg",
    "icon-bets": "assets/icons/bets.svg",
    "icon-time": "assets/icons/time.svg",
    "icon-bonus": "assets/client-area/icon-menu-my-bonuses.svg",
    "icon-promo": "assets/icons/icon-promo-code.svg",
    "icon-how-works": "assets/desktop/tournament/how-it-works.svg",
    "icon-leader": "assets/desktop/tournament/leaderboard.svg",
    "icon-prize": "assets/desktop/tournament/prizes.svg",
    "icon-score": "assets/desktop/tournament/scoring.svg",
    "icon-info-dark": "assets/icons/icon-info.svg",
    "icon-none": "assets/icons/icon-none-round.svg",
    "icon-cpassword": "assets/client-area/icon-menu-change%20password.svg",
    "icon-bonus-wheel": "assets/icons/icon-lemon-lottery-wheel-bonus.png",
    "icon-m-cashback": "assets/mobile/client-area/icon-cashback-mobile-50x50.png",
    "icon-gold-cup": "assets/desktop/tournament/gold-cup.svg",
    "icon-hour-glass": "assets/mobile/hour-glass.svg",
    "icon-tournament": "assets/desktop/tournament/title-icon.svg",
    "icon-box-on": "assets/mobile/client-area/icon-box-mobile-on-50x50.png",
    "icon-player-choice": "assets/icons/icon-players-choice.svg",
    "icon-deposit-game": "assets/icons/icon-deposit-game.svg",
    "icon-game-history": "assets/desktop/game-history-hamburger-icon.svg",
    "icon-edit-no-outline": "assets/icons/icon-edit-no-outline-yellow.svg",
    "icon-menu-btn": "assets/desktop/ham-btn.svg",
    "icon-profile-info": "assets/mobile/client-area/icon-box-mobile-on-50x50.png",
    "icon-support": "assets/desktop/support-page.svg",
    "icon-faq": "assets/desktop/faq-page.svg",
    "icon-self-exclusion": "assets/desktop/self-exclusion-page.svg",
    "icon-client-box-on": "assets/desktop/client-area/icon-box-desktop-on-70x70.png",
    "icon-wheel-on": "assets/desktop/client-area/icon-wheel-desktop-on-120x120.png",
    "icon-treasure-box": "assets/icons/icon-treasure-box-closed.png",
    "icon-teaser-box": "assets/mobile/teaser-bonus-box.svg",
    "icon-email": "assets/desktop/mail-icon.svg",
    "icon-sent-email": "assets/desktop/sent-email.svg",
    "icon-bonus-box": "assets/icons/icon-bonus-box-payment.png",
    "icon-arrow-down": "assets/icons/icon-arrow-down.svg",
    "icon-home": "assets/desktop/home.svg",
    "icon-slots": "assets/desktop/icon-menu-slots.svg",
    "icon-live-casino": "assets/desktop/cards.svg",
    "icon-try-game-close": "assets/icons/close-game-icon.svg",
    "icon-tournament-info": "assets/icons/tournaments/icon-tournament-info.svg",
    "icon-inbox": "assets/icons/icon-inbox-sticky.svg",
    "icon-header-tournament": "icon-tournaments.svg",
    "icon-rule": "assets/desktop/tournament/how-it-works.svg",
    "icon-list": "assets/desktop/tournament/leaderboard.svg",
    "icon-mission": "assets/desktop/tournament/prizes.svg",
    "icon-mission-logo": "assets/icons/icon-missions-main-icon-mobile.svg",
    "icon-exclusive-desktop": "assets/icons/tag-lemon-exclusive-desktop.svg",
    "icon-exclusive-mobile": "assets/icons/tag-lemon-exclusive-mobile.svg",
    "icon-inbox-empty": "assets/icons/empty-inbox.png",
    "jackpot-avatar": "assets/playbison/icon-avatar.gif",
    "icon-refresh": "assets/icons/icon-refresh.svg",
    "icon-refresh-short" : "assets/icons/icon-refresh-short.svg",
    "icon-skeleton" : "assets/images/tournament-skeleton.svg",
    "promo-banner" : "assets/playbison/icon-promotion-banner.png",
    "success-tick": "assets/playbison/bioson-success-image.png",
    "icon-phone" : "assets/playbison/icon-phone.png"
}

const playbison_defaults = {
    ...default_values,
    "logo": "assets/logo/logo-bison-light.svg",
    "logo-desktop": "assets/logo/logo-bison-light.svg",
    // "logo": "assets/logo/logo-playbison-xmas.svg",
    // "logo-desktop": "assets/logo/logo-playbison-xmas.svg",
    "title-substitute": "Bison Casino",
    "search-icon": "assets/playbison/icon-search-sticky.png",
    "search-icon-page": "assets/playbison/icon_search.png",
    "nothing-found-icon": "assets/playbison/nothing-found-playbison.png",
    "signup-step-one": "assets/playbison/icon-signup-one.png",
    "signup-step-two": "assets/playbison/icon-signup-two.png",
    "signup-step-three": "assets/playbison/icon-signup-three.png",
    "signup-banner": "assets/playbison/signup-banner.png",
    "tournament-icon": "assets/playbison/icon-tournaments.png",
    "icon-add": "assets/playbison/icon-add.png",
    "sticky-profile": "assets/playbison/icon-profile-sticky.png",
    "sticky-category": "assets/playbison/icon-categories-sticky.png",
    "sticky-live": "assets/playbison/icon-live-casino-sticky.png",
    "sticky-wallet": "assets/playbison/icon-wallet-sticky.png",
    "sticky-search": "assets/playbison/icon-search-sticky.png",
    "btn-close": "assets/playbison/icon-close.svg",
    "btn-cancel": "assets/playbison/icon-close.svg",
    "btn-error": "assets/playbison/icon-error.png",
    "btn-edit": "assets/playbison/icon-edit.png",
    "btn-edit-full": "assets/playbison/icon-edit-full.png",
    "icon-notification": "assets/playbison/icon-notification.png",
    "icon-operator": "playbison/icon-operators.png",
    "icon-pay-history": "assets/playbison/icon-payment-history.png",
    "icon-withdrawal": "assets/playbison/icon-withdrawal.png",
    "icon-edit-profile": "playbison/icon-edit-profile.png",
    "icon-edit-profile-page": "playbison/icon-profile-edit.png",
    "icon-profile-page": "playbison/icon-profile.png",
    "icon-deposit": "assets/playbison/icon-deposit.png",
    "icon-right": "assets/playbison/icon-arrow-right.svg",
    "icon-info": "assets/playbison/icon-info.png",
    "icon-info-profile": "assets/client-area/icon-info.svg",
    "icon-winnings": "assets/playbison/winnings.png",
    "icon-bets": "assets/playbison/bets.png",
    "icon-time": "assets/playbison/time.png",
    "icon-bonus": "assets/playbison/icon-bonus.png",
    "icon-promo": "assets/playbison/icon-promo-code.png",
    "icon-how-works": "assets/playbison/icon-rule.png",
    "icon-leader": "assets/playbison/icon-tournaments-leaderboard.png",
    "icon-prize": "assets/playbison/icon-tournaments-prizes.png",
    "icon-score": "assets/playbison/icon-tournaments-points.png",
    "icon-info-dark": "assets/playbison/icon-info.png",
    "icon-none": "assets/playbison/icon-none-round.png",
    "icon-cpassword": "assets/playbison/icon-menu-change-password.png",
    "icon-bonus-wheel": "assets/playbison/icon-lemon-lottery-wheel-bonus.png",
    // "icon-m-wheel-off":"assets/playbison/icon-wheel-off-mobile-84x84.png",
    // "icon-m-wheel-on" : "assets/playbison/icon-wheel-on-mobile-84x84.png",
    "icon-m-cashback": "assets/playbison/icon-cashback-mobile-50x50.png",
    "icon-gold-cup": "assets/playbison/trophy.png",
    "icon-gold-cup-1": "assets/playbison/trophy-1.png",
    "icon-gold-cup-2": "assets/playbison/trophy-2.png",
    "icon-gold-cup-3": "assets/playbison/trophy-3.png",
    "icon-hour-glass": "assets/playbison/hour-glass.png",
    "icon-tournament": "assets/playbison/icon-tournaments.png",
    "icon-box-on": "assets/playbison/icon-scratch-cards-profile-55x55.png",
    // "icon-box-off" : "assets/playbison/icon-box-mobile-off-50x50.png",
    "icon-player-choice": "assets/playbison/icon-players-choice.png",
    "icon-deposit-game": "assets/playbison/icon-deposit-game.svg",
    "icon-game-history": "assets/playbison/icon-game-history.png",
    "icon-edit-no-outline": "assets/playbison/icon-edit-no-outline-yellow.png",
    "icon-menu-btn": "assets/playbison/menu-btn.png",
    "icon-profile-info": "assets/playbison/icon-scratch-cards-profile-55x55.png",
    "icon-support": "assets/playbison/icon-support.png",
    "icon-faq": "assets/playbison/icon-faq.png",
    "icon-self-exclusion": "assets/playbison/icon-self-exclusion.png",
    "icon-client-box-on": "assets/playbison/icon-scratch-cards-profile-55x55.png",
    "icon-wheel-on": "assets/playbison/icon-spin-and-win-120x120.png",
    "icon-treasure-box": "assets/playbison/icon-scratch-cards-profile-55x55.png",
    "icon-teaser-box": "assets/playbison/icon-spin-and-win-120x120.png",
    "icon-email": "assets/playbison/mail-icon.svg",
    "icon-sent-email": "assets/playbison/sent-email.svg",
    "icon-bonus-box": "assets/playbison/icon-bonus-box.svg",
    "icon-arrow-down": "assets/playbison/icon-arrow-down.png",
    "compaign-switch": "assets/playbison/icon-menu-promotions.svg",
    "compaign-info-icon": "assets/playbison/icon-info.svg",
    "icon-home": "assets/playbison/icon-home.png",
    "icon-slots": "assets/playbison/icon-slots.png",
    "icon-live-casino": "assets/playbison/icon-live-casino.png",
    "icon-try-game-close": "assets/playbison/icon-try-game-close.svg",
    "icon-tournament-info": "assets/playbison/icon-tournament-info.svg",
    "icon-inbox": "assets/playbison/icon-messages-inbox.png",
    "icon-header-tournament": "icon-tournaments.png",
    "icon-rule": "assets/playbison/icon-mission-info.svg",
    "icon-list": "assets/playbison/icon-mission-games.svg",
    "icon-mission": "assets/playbison/icon-mission.svg",
    "icon-mission-home": "assets/playbison/icon-missions-home.svg",
    "batch-icon-mission": "assets/playbison/icon_mission_active.svg",
    "batch-icon-schedule": "assets/playbison/icon_mission_schedule.svg",
    "icon-mission-logo": "assets/playbison/icons/icon-missions-main-icon-mobile.svg",
    "icon-exclusive-desktop": "assets/playbison/tag-playbison-exclusive.svg",
    "icon-exclusive-mobile": "assets/playbison/tag-playbison-exclusive.svg",
    "icon-inbox-empty": "assets/playbison/icon-inbox-empty.png",
    "icon-refresh": "assets/playbison/icons/icon-refresh.svg",
    "icon-refresh-short" : "assets/playbison/icons/icon-refresh-short.svg",
    "icon-skeleton" : "assets/playbison/icon-skeleton.svg",
    "badge-hot-mobile" : "assets/playbison/icons/badge-bisoncasino-hot-mobile.svg",
    "badge-hot-desktop" : "assets/playbison/icons/badge-bisoncasino-hot-desktop.svg",
    "badge-bigwin-mobile" : "assets/playbison/icons/badge-bisoncasino-bigwin-mobile.svg",
    "badge-bigwin-desktop" : "assets/playbison/icons/badge-bisoncasino-bigwin-desktop.svg",
    "login-welcomeback":"assets/playbison/login/welcome-back-login-img.svg",
    "login-wrongpassword":"assets/playbison/login/wrongpassword-img.svg",
    "lost-password":"assets/playbison/login/lost-password-img.svg",
    "newpassword":"assets/playbison/login/newpassword-img.svg",
    "login-success":"assets/playbison/login/login-success-img.svg",
    "login-email-icon":"assets/playbison/login/icon-messages.svg",
    "eye-close-icon":"assets/playbison/login/icon-eyedisabled.svg",
    "eye-open-icon":"assets/playbison/login/icon-eye.svg",
    "login-error-icon":"assets/playbison/login/login-warning-icon.svg",
    "my-games-icon":"assets/icons/categories/icon-my-games.png",
    "icon-exclamation":"assets/icons/icon-exclamation.svg",
    "change-password-img":"assets/playbison/login/change-pass-banner.svg",
    "icon-menu-change-password":"assets/playbison/login/icon-menu-change password.svg",
    "icon-vip-leef":"assets/playbison/bison-vip-leaves.svg",
    "icon-my-game":"assets/playbison/icons/icon-my-game-72x72.svg",
    "icon-wallet-bonus-black": "assets/playbison/icons/wallet-bonus-icon.svg"
}

export const LICENCE_CONFIG_MAP = {
    "default": default_values,
    "orange_curacao": {
        "logo": "assets/logo/orange-logo.svg",
        "logo-desktop": "assets/logo/orange-logo.svg",
        "full-logo": "assets/logo/orange-logo.svg",
        "title-substitute": "Orange Casino",

    },
    "playbison": {
        ...playbison_defaults
    },
    "playbull_vip": {
        ...default_values,
        "name": "Playbull VIP",
        "logo": "assets/bisonwin/logo-color.png",
        "logo-desktop": "assets/bisonwin/logo-color.png",
        "title-substitute": "Bison Casino",
        "search-icon": "assets/playbison/icon-search-sticky.png",
        "signup-step-one": "assets/playbison/icon-signup-one.png",
        "signup-step-two": "assets/playbison/icon-signup-two.png",
        "signup-step-three": "assets/playbison/icon-signup-three.png",
        "signup-banner": "assets/playbison/signup-banner.png",
        "tournament-icon": "assets/playbison/icon-tournaments.png",
        "icon-add": "assets/playbison/icon-add.png",
        "sticky-profile": "assets/playbison/icon-profile-sticky.png",
        "sticky-category": "assets/playbison/icon-categories-sticky.png",
        "sticky-live": "assets/playbison/icon-live-casino-sticky.png",
        "sticky-wallet": "assets/playbison/icon-wallet-sticky.png",
        "sticky-search": "assets/playbison/icon-search-sticky.png",
        "btn-close": "assets/playbison/icon-close.svg",
        "btn-cancel": "assets/playbison/icon-close.svg",
        "btn-error": "assets/playbison/icon-error.png",
        "btn-edit": "assets/playbison/icon-edit.png",
        "btn-edit-full": "assets/playbison/icon-edit-full.png",
        "icon-notification": "assets/playbison/icon-notification.png",
        "icon-operator": "playbison/icon-operators.png",
        "icon-pay-history": "assets/playbison/pay-history.png",
        "icon-withdrawal": "assets/playbison/icon-withdrawal.png",
        "icon-edit-profile": "playbison/icon-edit-profile.png",
        "icon-deposit": "assets/playbison/icon-deposit.png",
        "icon-right": "assets/playbison/icon-arrow-right.svg",
        "icon-info": "assets/playbison/icon-info.png",
        "icon-winnings": "assets/playbison/winnings.png",
        "icon-bets": "assets/playbison/bets.png",
        "icon-time": "assets/playbison/time.png",
        "icon-bonus": "assets/playbison/icon-bonus.png",
        "icon-promo": "assets/playbison/icon-promo-code.png",
        "icon-how-works": "assets/playbison/icon-rule.svg",
        "icon-leader": "assets/playbison/icon-tournaments-leaderboard.svg",
        "icon-prize": "assets/playbison/icon-tournaments-prizes.svg",
        "icon-score": "assets/playbison/icon-tournaments-points.svg",
        "icon-info-dark": "assets/playbison/icon-info.png",
        "icon-none": "assets/playbison/icon-none-round.png",
        "icon-cpassword": "assets/playbison/icon-menu-change-password.png",
        "icon-bonus-wheel": "assets/playbison/icon-lemon-lottery-wheel-bonus.png",
        // "icon-m-wheel-off":"assets/playbison/icon-wheel-off-mobile-84x84.png",
        // "icon-m-wheel-on" : "assets/playbison/icon-wheel-on-mobile-84x84.png",
        "icon-m-cashback": "assets/playbison/icon-cashback-mobile-50x50.png",
        "icon-gold-cup": "assets/playbison/trophy.png",
        "icon-hour-glass": "assets/playbison/hour-glass.png",
        "icon-tournament": "assets/playbison/icon-tournaments.png",
        "icon-box-on": "assets/playbison/icon-scratch-cards-profile-55x55.png",
        // "icon-box-off" : "assets/playbison/icon-box-mobile-off-50x50.png",
        "icon-player-choice": "assets/playbison/icon-players-choice.png",
        "icon-deposit-game": "assets/playbison/icon-deposit-game.svg",
        "icon-game-history": "assets/playbison/game-history.png",
        "icon-edit-no-outline": "assets/playbison/icon-edit-no-outline-yellow.png",
        "icon-menu-btn": "assets/playbison/menu-btn.png",
        "icon-profile-info": "assets/playbison/icon-scratch-cards-profile-55x55.png",
        "icon-support": "assets/playbison/icon-support.png",
        "icon-faq": "assets/playbison/icon-faq.png",
        "icon-self-exclusion": "assets/playbison/icon-self-exclusion.png",
        "icon-client-box-on": "assets/playbison/icon-scratch-cards-profile-55x55.png",
        "icon-wheel-on": "assets/playbison/icon-bonus-120x120.png",
        "icon-treasure-box": "assets/playbison/icon-scratch-cards-profile-55x55.png",
        "icon-teaser-box": "assets/playbison/icon-bonus-120x120.png",
        "icon-email": "assets/playbison/mail-icon.svg",
        "icon-sent-email": "assets/playbison/sent-email.svg",
        "icon-bonus-box": "assets/playbison/icon-bonus-box.svg",
        "icon-arrow-down": "assets/playbison/icon-arrow-down.png",
        "icon-home": "assets/playbison/icon-home.svg",
        "icon-slots": "assets/playbison/icon-slots.png",
        "icon-live-casino": "assets/playbison/icon-live-casino.png",
        "icon-inbox": "assets/playbison/icon-messages-inbox.png",
        "icon-header-tournament": "icon-tournaments.png",
        "icon-rule": "assets/playbison/icon-rule.svg",
        "icon-list": "assets/playbison/icon-list.svg",
        "icon-mission": "assets/playbison/icon-mission.svg",
        "icon-mission-logo": "assets/playbison/icon-mission-logo.png",
        "icon-exclusive-desktop": "assets/playbison/tag-playbison-exclusive.svg",
        "icon-exclusive-mobile": "assets/playbison/tag-playbison-exclusive.svg",
        "icon-inbox-empty": "assets/playbison/icon-inbox-empty.png"
    },
    "bison_win": {
        ...playbison_defaults,
        "name": "Bison Win",
        "logo": "assets/bisonwin/logo-color.png",
        "logo-desktop": "assets/bisonwin/logo-color.png",
    },
    "bison_casino": {
        ...playbison_defaults,
        "name": "Bison Casino",
        "logo": "assets/bisoncasino/logo-color.svg",
        "logo-desktop": "assets/bisoncasino/logo-color.svg",
    },
    "bison_india": {
        ...playbison_defaults,
        "name": "Bison Casino",
        "logo": "assets/bisoncasino/logo-color.svg",
        "logo-desktop": "assets/bisoncasino/logo-color.svg",
        "jackpot-avatar": "assets/playbison/icon-indian-avatar.gif",

    },
}

