<div class="profile-wrapper mobile" fxLayout="column" fxLayoutAlign="start">
  <div fxFlex="none" class="top-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <ng-container *ngIf="avatar">
      <div class="vip-avatar" *ngIf="userRole else regularAvatar">
        <div class="vip-img-content">
          <img class="vip-player-img" src="{{avatar}}" />
          <div class="role" i18n>vip</div>
        </div>
        <img class="vip-leef" src="{{licenceService.getKey('icon-vip-leef')}}">
      </div>
      <ng-template #regularAvatar>
        <img class="regular-player-img" src="{{avatar}}" />
      </ng-template>
    </ng-container>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px" style="overflow: hidden;">
      <div *ngIf="verified" class="verified" i18n>Verified</div>
      <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;width: 100%;" [title]="email" i18n>{{email}}</div>
    </div>
  </div>
  <div fxFlex="none" class="level-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <div class="level-container-img-box">
      <div class="image" [ngStyle]="{'background': userRole ? 'var(--image-border-gradient)' : 'var(--border-color)'}">
        <img *ngIf="lemonIconNameIndex" src="assets/playbison/levels/icon-playbison-level-{{lemonIconNameIndex}}.png" alt="icon-gamification" />
        <div *ngIf="playerLoyalityName" class="label" [ngClass]="{'vip-label':userRole}">{{playerLoyalityName}}</div>
      </div>
      <div class="label-level" i18n>{{playerLevelPosition}}</div>
    </div>
    <div class="level-progress-container" fxLayout="column" fxLayoutGap="8px">
      <div class="level-progress-number" fxLayout="row" fxLayoutAlign="space-around center">
        <div class="number" *ngFor="let levelValue of playerCurrentLevelList">{{levelValue}}</div>
      </div>
      <div class="profile-level-progress">
        <mat-progress-bar color="accent" mode="determinate" [value]="gamificationPercentFill" [ngClass]="gamificationPercentFill===100 ? 'value-comleted' : 'value-not-comleted'">
        </mat-progress-bar>
        <div class="level-divider-container" fxLayout="row" fxLayoutAlign="space-evenly center">
          <div class="level-divider"></div>
          <div class="level-divider"></div>
          <div class="level-divider"></div>
          <div class="level-divider"></div>
        </div>
      </div>
    </div>
    <div class="level-container-img-box">
      <div class="image">
        <img *ngIf="lemonNextLevelIconNameIndex" src="assets/playbison/levels/icon-playbison-level-{{lemonNextLevelIconNameIndex}}.png" alt="icon-gamification" />
        <div *ngIf="playerLoyalityName" class="label" [ngClass]="{'vip-label':userRole}">{{playerNextLoyalityName}}</div>
      </div>
      <div class="label-level" i18n>{{playerNextLevelPosition}}</div>
    </div>
  </div>
</div>
<div class="items">
  <div class="item-wrapper light">
    <div class="item">
      <div class="spinner-container">
        <div class="spinner-background" [ngClass]="{'spinner-background-active':isPrizeBoxActive && showGamification}">
          <img *ngIf="isPrizeBoxActive && showGamification" src="assets/playbison/icon-scratch-cards-profile-55x55.png" alt="icon-gamification" />
          <img *ngIf="!isPrizeBoxActive && showGamification" src="assets/playbison/icon-scratch-cards-profile-55x55.png" alt="icon-gamification" />
        </div>
        <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="64" style="stroke-linecap: round;">
        </mat-progress-spinner>
      </div>
      <div class="text-container">
        <div class="title" i18n>Scratch Cards</div>
        <div *ngIf="isPrizeBoxActive" style="color: #ADBDDE;" i18n>Collect your prize!</div>
        <div *ngIf="!isPrizeBoxActive" style="color: #ADBDDE;" i18n>Level {{gamificationLevel}} completed in {{gamificationPercentFill.toFixed(2)}}%</div>
      </div>

      <div class="info">
        <a [routerLink]="scratchCardsInfoUrl" rel="noreferrer" routerLinkActive="active">
          <img src="assets/client-area/icon-info.svg" />
        </a>
      </div>
    </div>
    <button round-button filled="filled" color="accent" (click)="openPrizeBox()" [disabled]="!isPrizeBoxActive" i18n>Open!</button>
  </div>

  <div *ngIf="!cashBackTwoActive" class="item-wrapper light">
    <div class="item">
      <div class="spinner-container">
        <div class="spinner-background" [ngClass]="{'spinner-background-active':!!userChargebackToClaim && showGamification}">
          <img *ngIf="!userChargebackToClaim && showGamification" src="assets/playbison/icon-cashback-mobile-50x50.png" alt="icon-gamification" />
          <img *ngIf="!!userChargebackToClaim && showGamification" src="assets/playbison/icon-cashback-mobile-50x50.png" alt="icon-gamification" />
        </div>
        <mat-progress-spinner *ngIf="!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="64" style="stroke-linecap: round;">
        </mat-progress-spinner>
        <mat-progress-spinner *ngIf="!!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="100" [diameter]="64" style="stroke-linecap: round;">
        </mat-progress-spinner>
      </div>
      <div class="text-container">
        <div i18n>Royal Cashback earned:</div>
        <div class="title" i18n>{{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.value.toFixed(2)}} {{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.currency}}</div>
        <div *ngIf="!userChargebackToClaim" style="color: #ADBDDE;"><span i18n>Next payout in:</span>&nbsp;{{chargebackFormatted}}</div>
      </div>
      <div class="info">
        <a [routerLink]="cashbackInfoUrl" rel="noreferrer" routerLinkActive="active">
          <img src="{{licenceService.getKey('icon-info')}}">
        </a>
      </div>
    </div>
    <button round-button filled="filled" color="accent" (click)="claimCashback()" [disabled]="!userChargebackToClaim" i18n>Claim!</button>
  </div>

  <div *ngIf="cashBackTwoActive" class="profile-cashback-container">
    <swiper fxFlex="auto" class="profile-cashback-swiper" [config]="config">
      <ng-template swiperSlide>
        <div class="item-wrapper light">
          <div class="item">
            <div class="spinner-container">
              <div class="spinner-background" [ngClass]="{'spinner-background-active':!!userChargebackToClaim && showGamification}">
                <img *ngIf="!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-off-80x80.png" alt="icon-gamification" />
                <img *ngIf="!!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-on-80x80.png" alt="icon-gamification" />
              </div>
              <mat-progress-spinner *ngIf="!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="64" style="stroke-linecap: round;">
              </mat-progress-spinner>
              <mat-progress-spinner *ngIf="!!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="100" [diameter]="64" style="stroke-linecap: round;">
              </mat-progress-spinner>
            </div>
            <div class="text-container">
              <div i18n>Cashback 1 earned:</div>
              <div class="title" i18n>{{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.value.toFixed(2)}} {{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.currency}}</div>
              <div *ngIf="!userChargebackToClaim" style="color: #DADADA;"><span i18n>Next payout in:</span>&nbsp;{{chargebackFormatted}}</div>
            </div>
            <div class="info">
              <a [routerLink]="cashbackInfoUrl" rel="noreferrer" routerLinkActive="active">
                <img src="assets/client-area/icon-info.svg">
              </a>
            </div>
          </div>
          <button round-button filled="filled" color="warn" (click)="claimCashback()" [disabled]="!userChargebackToClaim" i18n>Claim!</button>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="item-wrapper light">
          <div class="item">
            <div class="spinner-container">
              <div class="spinner-background" [ngClass]="{'spinner-background-active':!!userChargebackToClaim && showGamification}">
                <img *ngIf="!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-2-off.png" alt="icon-gamification" />
                <img *ngIf="!!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-2-on.png" alt="icon-gamification" />
              </div>
              <mat-progress-spinner *ngIf="!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="64" style="stroke-linecap: round;">
              </mat-progress-spinner>
              <mat-progress-spinner *ngIf="!!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="100" [diameter]="64" style="stroke-linecap: round;">
              </mat-progress-spinner>
            </div>
            <div class="text-container">
              <div i18n>Cashback 2 earned:</div>
              <div class="title" i18n>{{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.value.toFixed(2)}} {{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.currency}}</div>
              <div *ngIf="!userChargebackToClaim" style="color: #DADADA;"><span i18n>Next payout in:</span>&nbsp;{{chargebackFormatted}}</div>
            </div>
            <div class="info">
              <a [routerLink]="cashbackInfoUrl" rel="noreferrer" routerLinkActive="active">
                <img src="assets/client-area/icon-info.svg">
              </a>
            </div>
          </div>
          <button round-button filled="filled" color="warn" (click)="claimCashback()" [disabled]="!userChargebackToClaim" i18n>Claim!</button>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div class="item-wrapper light">
    <div class="item">
      <div class="icon-container">
        <img [ngClass]="{'icon-wheel-off': !userBonusLotteryResponse}" src="{{licenceService.getKey('icon-wheel-on')}}" />
        <!-- <div *ngIf="userBonusLotteryResponse" class="animation" fxLayoutAlign="center center">
          <div [ngStyle]="{'height.px': canvasHeight}" #pixiAnimation></div>
        </div> -->
      </div>
      <div class="text-container">
        <div class="title" i18n>Spin & Win</div>
        <div *ngIf="userBonusLotteryResponse" style="color: #ADBDDE;" i18n>Collect your free ticket!</div>
        <div *ngIf="!userBonusLotteryResponse" style="color: #ADBDDE;" i18n>Inactive</div>
      </div>
      <div class="info">
        <a [routerLink]="lotteryInfoUrl" rel="noreferrer" routerLinkActive="active">
          <img src="assets/client-area/icon-info.svg">
        </a>
      </div>
    </div>
    <button round-button filled="filled" color="accent" (click)="openLotteryWheel()" [disabled]="!userBonusLotteryResponse" i18n>Spin!</button>
  </div>
</div>

<div class="items-dark">
  <div class="item-wrapper-dark">
    <div class="item">
      <div class="icon-container-dark">
        <img src="assets/playbison/icon-bonus.png" />
      </div>
      <div class="text-container">
        <div class="title" i18n>My Bonuses</div>
        <div i18n>Manage your bonuses</div>
      </div>
    </div>
    <button round-button filled="empty" color="warn" i18n [routerLink]="bonusesUrl" routerLinkActive="active">Proceed</button>
  </div>

  <div class="item-wrapper-dark dark">
    <div class="item">
      <div class="icon-container-dark">
        <img src="{{licenceService.getKey('icon-my-game')}}" />
      </div>
      <div class="text-container">
        <div class="title" i18n>My games</div>
        <div i18n>Your games collection</div>
      </div>
    </div>
    <button round-button filled="empty" color="warn" i18n [routerLink]="myGamesUrl" routerLinkActive="active">Proceed</button>
  </div>
</div>

<div class="divider-line">
  <mat-divider></mat-divider>
</div>

<div class="main-wrap">
  <div class="lighter-bg" fxLayout="column" fxLayoutAlign="start center" [routerLink]="editProfileUrl" routerLinkActive="active">
    <img src="assets/icons/{{licenceService.getKey('icon-edit-profile-page')}}">
    <div class="label mobile">
      <div class="profile-title" i18n appInsertBrBetweenWords>Edit Profile</div>
    </div>
  </div>

  <div class="lighter-bg" fxLayout="column" fxLayoutAlign="start center" [routerLink]="changePasswordUrl" routerLinkActive="active">
    <img src="{{licenceService.getKey('icon-cpassword')}}">
    <div class="label mobile">
      <div class="profile-title" i18n appInsertBrBetweenWords>Change Password</div>
    </div>
  </div>
</div>